<template>
	<div>
	<ContentHeader title="Data User &amp; Hak Akses" />
    <div class="content-header">
		<div class="container-fluid">
			<div class="card">
              <div class="card-header row d-flex align-items-center">
                <h3 class="card-title col-6">Data User &amp; Hak Akses</h3>
                <div class="col-6 text-right">
                  <router-link to="/tambah-user-dan-hak-akses"><button class="btn btn-sm btn-info" v-if="cabang_store.jenis == 'pusat'">Tambah</button></router-link> 
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <div class="row" v-if="toogleCabang">
                  <div class="col-lg-4">
                    <label>Pilih Cabang</label>
                    <multiselect 
                      v-model="cabang_select_value"
                      deselect-label="Can't remove this value"
                      track-by="nama"
                      label="nama"
                      placeholder=""
                      :options="cabang"
                      :loading="loading_cabang"
                      :allow-empty="false"
                      @update:model-value="getDataResult"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <span>Jumlah Baris</span>
                    <select class="custom-select form-control" v-model="page" @change="getDataResult">
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="45">45</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                  <div class="col-lg-6"></div>
                  <div class="col-lg-4">
                    <span>Cari Data</span>
                    <input type="text" class="form-control" v-model="kata_kunci" @keyup="getDataResult">
                  </div>
                </div>
                <table class="table table-bordered table-hover mt-4">
                  <thead>
                    <tr>
                      <th scope="col" class="text-sm" width="100px">NO</th>
                      <th scope="col" class="text-sm">NAMA</th>
                      <th scope="col" class="text-sm" width="200px">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in data_result" :key="row.id">
                      <td class="text-sm" scope="row" width="100px">{{ index + 1 }}</td>
                      <td class="text-sm">{{ row.name }}</td>
                      <td class="text-sm" width="200px">
                        <div class="btn-group">
                          <button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
                          <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                            <span class="sr-only"></span>
                          </button>
                          <div class="dropdown-menu" role="menu" style="">
                            <router-link :to="`/edit-user-dan-hak-akses/${row.id}`" class="dropdown-item text-sm">Edit</router-link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
                <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <nav aria-label="...">
                  <ul class="pagination">
                    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
                      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                    </li>
                    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
                      <a class="page-link" href="#">Next</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!-- /.card-footer-->
            </div>
		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'

const user = computed(() => store.getters['auth/user'])
const cabang_store = computed(() => store.getters['auth/cabang'])
const data_result = ref([])
const cek_data = ref(false)
const page = ref(10)
const links = ref([])
const pagination = ref([])
const memuat_data = ref(false)
const kata_kunci = ref('')

const getDataResult = async () => {
  data_result.value = []
  memuat_data.value = true
  cek_data.value = false

  let parameter = ''
  if (kata_kunci.value != '') {
    parameter = kata_kunci.value
  } else {
    parameter = 'emptyParameter'
  }

  let cabang_id = ''

  if (cabang_select_value.value == '') {
    cabang_id = user.value.cabang_id
  } else {
    cabang_id = cabang_select_value.value.id
  }

  let { data } = await axios.get(`api/hak_akses/getUser/${cabang_id}/${page.value}/${parameter}`)

  if (data != 'kosong') {
    memuat_data.value = false
    cek_data.value = false
    data_result.value = data.data
    links.value = data.links
      links.value.splice(0, 1)
      links.value.splice(links.value.length-1, 1)
      pagination.value = data
  } else {
    cek_data.value = true
    memuat_data.value = false
    data_result.value = []
  }
}

const paginateData = async (url) => {

    if (url != null) {
      data_result.value = []
      memuat_data.value = true

      let link_url = new URL(url)
      link_url = `${link_url.pathname}${link_url.search}`
      
      let { data } = await axios.get(`${link_url}`)

      
      if (data != 'kosong') {
        memuat_data.value = false
        data_result.value = data.data 
        links.value = data.links
        links.value.splice(0, 1)
        links.value.splice(links.value.length-1, 1)
        pagination.value = data
      }
    }
  }


const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
  let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

  if (response.data.jenis == 'pusat') {
    toogleCabang.value = true
    let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
    if (data != 'kosong') {
      cabang.value = data
      loading_cabang.value = false
    } else {
      cabang.value = []
      loading_cabang.value = false
    }
  }
}

onMounted(() => {
  getCabang()
  getDataResult()
})
</script>